import Link from "gatsby-link";
import React, { Component } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { EVENTS } from "@upsolve/shared";
import { track } from "../components/analytics/track";
import * as CONTENT_GROUPS from "../components/analytics/contentGroups";
import LearnListingPage from "../components/Learn/LearnListingPage.div";
import MainFooter from "../components/Layout/Footer";
import MainHeader from "../components/Layout/Header";
import SEO from "../components/SEO/SEO";
import TopLevelLayout from "../components/Layout/TopLevelLayout";
import { H3, H6 } from "../components/Type/Display.h";

const LegalDefinitionsPage = styled(LearnListingPage)`
  .learn__listing {
    text-align: center;
  }
`;

const contentGroup = CONTENT_GROUPS.LEGAL_DEFINITION;
const templateProps = {
  pageComponentName: "LegalDefinitionsListing",
  pageComponentVersion: 0,
};
const trackingPageContext = { contentGroup, ...templateProps };

class LegalDefinitionsListing extends Component {

  componentDidMount() {
    track(EVENTS.PAGE_VIEW, { pageContext: trackingPageContext });
  }

  render() {
    const terms = this.props.data.allContentfulGlossaryDefinition.edges.map((e) => e.node);
    return (
      <TopLevelLayout>
        <div className="index-container">
          <SEO
            title="Bankruptcy Legal Definitions | Upsolve"
            description="Bankruptcy legal definitions made simple and clear."
            image="https://upsolve.org/images/meta-legalaid.png"
            url="https://upsolve.org/learn/"
            jsonLD={[
              {
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: [
                  {
                    "@context": "http://schema.org",
                    "@type": "ListItem",
                    position: 1,
                    item: {
                      "@id": "https://upsolve.org/legal-definitions/",
                      name: "Legal Definitions",
                    },
                  },
                ],
              },
            ]}
          />
          <MainHeader trackingPageContext={trackingPageContext} />
          <main>
            <LegalDefinitionsPage>
              <div className="learn__image" />
              <div className="learn__header">
                <H3 as="h1">Legal Definitions</H3>
                <H6 as="h2">Bankruptcy legal definitions made simple and clear.</H6>
              </div>
              <div className="learn__listing">
                <ul>
                  {terms.map((term) => (
                    <li key={term.id}>
                      {term.term != null && (
                        <Link to={`/legal-definitions/${term.slug.toLowerCase()}/`}>{term.term}</Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </LegalDefinitionsPage>
          </main>
          <MainFooter />
        </div>
      </TopLevelLayout>
    );
  }
}

export default LegalDefinitionsListing;

/* eslint no-undef: "off"*/
export const pageQuery = graphql`
  query GlossaryDefinitionsQuery {
    allContentfulGlossaryDefinition(sort: { fields: [term], order: ASC }) {
      edges {
        node {
          id
          slug
          term
          definition {
            definition
          }
          createdAt
        }
      }
    }
  }
`;
