import styled from "styled-components";
import Page from "../Layout/Page.div";

const LearnListingPage = styled(Page)`
  padding: 1em;
  .learn__header {
    text-align: center;
  }
  .learn__image {
    text-align: center;
    width: 100%;
    height: 210px;
    background: url(${require("../../../static/images/meta-legalaid.png")});
    background-size: cover;
    background-position: 0 -45px;
    background-repeat: no-repeat;
    top: -2em;
    @media (max-width: 45em) {
      margin-top: 0;
      height: 70px;
      background-position: 0 -20px;
    }
  }
  .learn__listing {
    .learn__listing__select {
      width: 100%;
      display: flex;
      justify-content: center;
      select {
        max-width: 480px;
        @media (max-width: 45em) {
          width: 100%;
        }
      }
    }
    .learn__listing__stories {
      display: flex;
      flex-wrap: wrap;
      padding: 3em 0;
      justify-content: center;
      & > div {
        flex: 1 1 240px;
      }
    }
  }
`;

export default LearnListingPage;
